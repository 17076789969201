import React, { Component } from 'react';
import { RouterProvider } from 'react-router-dom'
import { AppConsumer } from "./components/AppContext";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { IContext } from "./interfaces/context";
import * as Analytics from './util/Analytics';
import { Flip, ToastContainer } from 'react-toastify';

import './styles/main.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import ViewLoader from './components/ViewLoader';
import { router } from './Router';
import axios from 'axios';

interface AppState {
    loading: boolean;
    logoUrl?: string;
    customCss?: string;
}

interface IAppProps {
    ctx: IContext;
}

type PromiseCanceller = { cancelled: boolean };

class App extends Component<IAppProps, AppState> {

    constructor(props: IAppProps) {
        super(props);
        this.state = {
            loading: true
        };
    }


    convertToBase64(binaryData: ArrayBuffer, mimeType: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const blob = new Blob([binaryData], { type: mimeType });
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    async updateBackground(canceller: PromiseCanceller) {
        const bgUrl = await this.props.ctx.actions!.getBackgroundUrl();
        if (canceller.cancelled) return;
        const response = await axios.get(bgUrl, { responseType: 'arraybuffer' });

        const base64data = await this.convertToBase64(response.data, response.headers['content-type']);
        if (canceller.cancelled) return;

        console.debug("Updating background with base64 data");
        document.body.style.backgroundImage = `url('${base64data}')`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
    }

    async updateLogo(canceller: PromiseCanceller) {
        const logoUrl = await this.props.ctx.actions!.getLogoUrl();
        if (canceller.cancelled) return;
        const response = await axios.get(logoUrl, { responseType: 'arraybuffer' });

        if(!logoUrl.includes("logo-debq")) {
            const base64data = await this.convertToBase64(response.data, response.headers['content-type']);
            if (canceller.cancelled) return;
            this.setState({
                logoUrl: base64data
            });
        }
    }

    async updateCss(canceller: PromiseCanceller) {
        let customCss = await this.props.ctx.actions!.getCustomCss();
        customCss = customCss.replace(/(!important)?\s*;+/ig, "!important;")
        if (canceller.cancelled) return;
        await new Promise<void>((res) =>
            this.setState({ customCss }, res));
    }

    gtagLoaded = false;
    initGTAG() {
        if (this.gtagLoaded) return;
        if (this.props.ctx.state.company?.hasGoogleAnalytics) {
            this.gtagLoaded = true;
            Analytics.initialize(this.props.ctx.state.company.googleAnalyticsId);
        } else {
            Analytics.deinitialize();
        }
    }

    async setLoading(loading: boolean){
        await new Promise<void>((res) =>
            this.setState({ loading }, res));
    }

    updateResourcesCanceller: PromiseCanceller = { cancelled: true };

    async updateResources() {
        await this.setLoading(true);

        this.updateResourcesCanceller.cancelled = true;
        const canceller = this.updateResourcesCanceller = { cancelled: false };

        const { company } = this.props.ctx.state;
        const { pathname, hash } = window.location;
        const isPreCompanySelection = ['companies', '404', '/'].some(
            route => pathname.includes(route) || hash.includes(route))
        if ((!company || !company.id) && !isPreCompanySelection)
            return;
        try {
            await Promise.all([
                this.updateCss(canceller),
                this.updateBackground(canceller),
                this.updateLogo(canceller),
            ]);
        } finally {
            if (!canceller.cancelled)
                await this.setLoading(false);
        }
    }

    async componentDidMount() {
        document.addEventListener('companySelected', () => {
            this.updateResources();
            this.initGTAG();
        });
        await this.updateResources();
        this.initGTAG();
    }

    render() {
        const { ctx } = this.props;
        const { logoUrl, loading } = this.state;
        document.title = ctx.t("Index.TITLE");

        // TODO investigate codesplitting/preloading when we have time
        // https://mono.software/2019/12/30/code-splitting-and-preloading-react-routes-with-webpack/
        // https://stackoverflow.com/a/58509569
        return (
            <ViewLoader loading={loading} fullscreen={false}>
                <ErrorBoundary>
                    <>
                        <style>
                            {/* Custom Styles */}
                            {
                                this.state.customCss
                            }
                        </style>

                        <div className={`${ctx.state.company?.retroStyle ? 'retro' : ''} main-container`}>
                            {
                                logoUrl &&
                                <div className={`${ctx.state.company?.retroStyle ? 'retroLogo' : 'hideLogo'}`}>
                                    <img src={logoUrl} className="content-box__logo-img" alt="Logo" />
                                </div>
                            }
                            <RouterProvider
                                router={router}
                                fallbackElement={<ViewLoader loading={true} fullscreen={true} />}
                            />
                        </div>

                        {/*These options can be changed on the toast function call*/}
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            transition={Flip}
                            theme={"colored"}
                        />
                    </>
                </ErrorBoundary>
            </ViewLoader>
        );
    }
}

/**
 * For access to the method and values of the context
 */
const AppRef = React.forwardRef<App>((props, ref) => (
    <AppConsumer>
        {(ctx: IContext) => <App {...props} ctx={ctx} ref={ref} />}
    </AppConsumer>
));

/**
 * For access to the history
 */
export default AppRef;
